import router from '@/router';
import store from '@/store';
import { getToken } from '@/utils/auth';

function addRoutes(routes) {
  routes.forEach((route) => {
    router.addRoute(route);
  });
}

router.beforeEach(async (to, from, next) => {
  router.app.$progress.start();
  const token = getToken();
  if (token) {
    // 判断用户是否已获取角色
    const hasRoles = store.state.user.roles && store.state.user.roles.length > 0;
    if (hasRoles) {
      next();
      router.app.$progress.done();
    } else {
      const { roles } = await store.dispatch('user/getInfo');
      const accessRoutes = await store.dispatch('permission/generateRoutes', roles);
      addRoutes(accessRoutes);
      next({ ...to, replace: true });
    }
  } else {
    next();
    router.app.$progress.done();
  }
});
