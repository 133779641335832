import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

// 批量导入子模块
const moduleFn = require.context('./modules', false, /\.js$/);
const modules = moduleFn.keys().reduce((p, c) => {
  let mod = moduleFn(c).default;
  // 统一补 namespaced:true
  mod = {
    namespaced: true,
    ...mod,
  };
  // 正则中的元组，0全匹配的成功的字符，数小括号，从左到右
  const modName = c.match(/\.\/(\w+)\.js$/)[1];
  // eslint-disable-next-line no-param-reassign
  p[modName] = mod;
  return p;
}, {});

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules,
});
