export default function dateFormat(originVal, format) {
  const date = new Date(originVal);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();
  if (format === 'CN') return `${y}年${m}月${d}日`;
  if (format === 'yyyy-mm') return `${y}`;
  if (format === 'dd') return `${m}-${d}`;
  return `${y}-${m}-${d}`;
}
