import request from '@/utils/request';

const User = {
  login({
    username, password, email, mobile,
  }) {
    return new Promise((resolve, reject) => {
      request
        .User()
        .logIn({
          username,
          password,
          email,
          mobile,
        })
        .then(() => {
          const user = request.User().current();
          const token = user ? user.getSessionToken() : '';
          resolve({
            token,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  getInfo() {
    return request.User().getInfo();
  },

  // eslint-disable-next-line object-curly-newline
  register({ username, password, email, mobile }) {
    return request.User().register({
      username,
      password,
      email,
      mobile,
    });
  },
  logout() {
    const user = request.User();
    if (user && user.current()) user.logOut();
  },
};

export default User;
