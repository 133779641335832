<template>
  <div class="nav-phone">
    <nav-btn @callback="handleNavBtnClick" :open.sync="navOpen" size="medium" type="plain" />
    <div class="nav-box" id="phone-nav" :class="{ hidden: !navOpen }">
      <ul class="nav">
        <li class="nav-item level-one" v-for="(item, index) in data" :key="index">
          <div class="title-box">
            <h3 class="title">
              <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
              <p @click="goto(item)">{{ item.name }}</p>
              <!-- <router-link :to="item.href">{{item.name}}</router-link> -->
            </h3>
            <i class="icon" v-if="item.children"></i>
          </div>
          <ul class="nav two" v-if="item.children">
            <li class="nav-item level-two" v-for="(itemtwo, indextwo) in item.children"
            :key="indextwo">
              <div class="title-box">
                <h3 class="title">
                  <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
                  <p @click="goto(itemtwo)">{{ itemtwo.name }}</p>
                </h3>
                <i class="icon" v-if="itemtwo.children"></i>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import NavBtn from './Button.vue';

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    // 用于计算 top高度 的元素的选择器
    topEl: {
      type: String,
      default: 'header',
    },
  },
  components: { NavBtn },
  data() {
    return {
      navOpen: false, // 导航打开状态
    };
  },
  updated() {
    this.runDom();
  },
  methods: {
    runDom() {
      const parentList = window.document.querySelectorAll('#phone-nav .nav-item');
      const iconSelector = '.title-box .icon';
      const toggleChildSelector = 'ul.nav';
      // eslint-disable-next-line no-restricted-syntax
      for (const item of parentList) {
        const icon = item.querySelector(iconSelector);
        if (icon) {
          icon.addEventListener('click', () => {
            // eslint-disable-next-line no-restricted-syntax
            for (const itemC of parentList) {
              console.log(itemC.classList);
              if (itemC.querySelector(iconSelector) !== this) {
                itemC.classList.remove('click');
                const childNav = itemC.querySelector(toggleChildSelector);
                if (childNav) childNav.style.height = '0px';
              }
            }
            item.classList.toggle('click');
            const childNav = item.querySelector(toggleChildSelector);
            if (childNav) childNav.toggleHeight(0.3, 'ease-out');
          });
        }
      }
      const nav = document.querySelector('#phone-nav');
      let oheight = document.querySelector(this.topEl).offsetHeight;
      nav.style.top = `${oheight}px`;
      nav.style.height = `calc(100vh - ${oheight}px)`;
      window.addEventListener('resize', () => {
        oheight = document.querySelector(this.topEl).offsetHeight;
        nav.style.top = `${oheight}px`;
        nav.style.height = `calc(100vh - ${oheight}px)`;
      });
    },
    handleNavBtnClick() {
      // console.log(status);
    },
    goto(item) {
      if (item.href) this.$router.push(item.href);
      this.navOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .nav-phone {
    font-size: 0;
    --background-color: rgba(0, 0, 0, 0.9);
    --font-color: #fff;
    --nav-height: 40px;

    .nav-box {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 999;
      width: 100%;
      height: 0;
      text-align: left;
      background-color: var(--background-color);
      transition: all 0.5s ease;
      opacity: 1;
      overflow-x: hidden;
      overflow-y: scroll;

      > ul.nav {
        padding: 0 20px;
        height: auto;

        li.nav-item {
          .title-box {
            position: relative;

            h3.title {
              font-weight: normal;

              p {
                display: block;
                line-height: var(--nav-height);
                font-size: 16px;
                color: var(--font-color);
              }
            }

            i.icon {
              position: absolute;
              top: 50%;
              right: 0;
              z-index: 1;
              width: var(--nav-height);
              height: var(--nav-height);
              transform: translateY(-50%);
              cursor: pointer;

              &:before {
                content: '';
                position: absolute;
                top: 50%;
                right: 50%;
                z-index: 1;
                width: 25%;
                height: 25%;
                border-right: 2px solid #c7c7cc;
                border-bottom: 2px solid #c7c7cc;
                transform-origin: center;
                transform: translate(50%, -50%) rotate(-45deg);
                transition: transform 0.25s ease-in-out;
              }
            }
          }

          .nav {
            height: 0;
            transition: height 0.3s ease-out 0s;
          }

          &.click {
            > .title-box {
              > i.icon {
                &:before {
                  transform: translate(50%, -50%) rotate(45deg);
                }
              }
            }
          }

          /* 一级栏目 */
          &.level-one {
            border-bottom: 1px solid #555555;

            &:last-child {
              border-bottom: none;
            }

            ul.nav.two {
              padding: 0 20px;
              overflow: hidden;

              li.nav-item {
                /* 二级栏目 */
                &.level-two {
                  border-bottom: 1px solid #555555;

                  &:last-child {
                    border-bottom: none;
                  }
                }
              }
            }
          }
        }
      }

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        color: transparent;
        box-shadow: inset 0 0 0 3px;
        transition: color 0.5s ease;

        &:hover {
          color: #999999;
        }
      }

      &::-webkit-scrollbar-thumb {
        width: 0;
        box-shadow: inset 0 0 0 3px;
        border-radius: 4px;
      }

      &.hidden {
        opacity: 0;
        height: 0 !important;
      }

      &:hover {
        color: var(--them-color);
      }
    }

    @media only screen and (min-width: 1025px) {
      display: none;
    }
  }
</style>
