<template>
  <el-upload
    ref="upload"
    :accept="accept"
    :action="action"
    :auto-upload="autoUpload"
    :disabled="disabled"
    :drag="drag"
    :multiple="multiple"
    :limit="limit"
    :list-type="listType"
    :file-list="fileList"
    :show-file-list="showFileList"
    :before-upload="handleBeforeUpload"
    :on-change="handleOnChange"
    :on-exceed="hanleOnExceed"
    :on-remove="handleOnRemove"
  >
    <!-- <slot></slot> -->
    <slot></slot>
    <slot name="tip"></slot>
  </el-upload>
</template>

<script>
export default {
  name: 'ExtElUpload',
  props: {
    // 接受文件类型
    accept: {
      type: String,
      default: 'image/*',
    },
    action: {
      type: String,
      default: '#',
    },
    // 自动上传
    autoUpload: {
      type: Boolean,
      default: false,
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false,
    },
    // 拖拽上传
    drag: {
      type: Boolean,
      default: false,
    },
    // 多选文件
    multiple: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 20,
    },
    // 文件列表类型
    listType: {
      type: String,
      default: 'text',
    },
    // 是否显示文件列表
    showFileList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  watch: {},
  methods: {
    // 上传文件之前的钩子
    handleBeforeUpload(file) {
      this.$emit('beforeUpload', file);
    },
    // 文件状态改变时的钩子
    handleOnChange(file, fileList) {
      this.$emit('onChange', file, fileList);
    },
    // 文件超出个数限制时的钩子
    hanleOnExceed(file, fileList) {
      this.$emit('onExceed', file, fileList);
    },
    // 删除文件之前的钩子
    handleOnRemove(file, fileList) {
      this.$emit('onRemove', file, fileList);
    },
  },
};
</script>

<style lang="scss" scoped></style>
