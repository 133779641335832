import userService from '@/services/user';
import {
  getToken,
  setToken,
  removeToken,
} from '@/utils/auth';

export default {
  state: {
    token: getToken(),
    roles: [],
  },
  getters: {},
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
  },
  actions: {
    // user login
    login({
      commit,
    }, {
      username,
      password,
      email,
      mobile,
    }) {
      return new Promise((resolve, reject) => {
        userService
          .login({
            username,
            password,
            email,
            mobile,
          })
          .then((res) => {
            if (res) {
              console.log(res);
              commit('SET_TOKEN', res.token);
              setToken(res.token); // 更新token
              resolve(); // 无需返回值
            } else {
              reject();
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // get user info
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        userService
          .getInfo()
          .then((res) => {
            const { roles } = res;
            commit('SET_ROLES', roles);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // user logout
    logout({
      commit,
    }) {
      commit('SET_TOKEN', '');
      commit('SET_ROLES', []);
      removeToken();
    },
  },
};
