<template>
  <el-input v-model="myModel"
    :placeholder="placeholder"
    @input="$emit('input', myModel)"></el-input>
</template>

<script>
export default {
  name: 'ExtElInput',
  model: {
    prop: 'model',
    event: 'input',
  },
  props: {
    model: {
      required: true,
      type: String || Number,
      default: '',
    },
    // 输入框占位文本
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      myModel: this.model,
    };
  },
  watch: {
    model(val) {
      this.myModel = val;
    },
  },
  methods: {},
};
</script>

<style lang='scss' scoped></style>
