import allRoutes, { asyncRoutes } from '@/router/routes';

const { backRoute } = allRoutes;

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some((role) => route.meta.roles.includes(role));
  }
  return true;
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
function filterAsyncRoutes(routes, roles) {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles);
      }
      res.push(tmp);
    }
  });

  return res;
}

export default {
  state: {
    backRoute: [], // 当前后台路由
  },
  getters: {},
  mutations: {
    SET_BACK_ROUTES: (state, routes) => {
      state.backRoute = backRoute.constantRoutes.concat(routes);
    },
  },
  actions: {
    generateRoutes({ commit }, roles) {
      return new Promise((resolve) => {
        let backAsyncRoutes = [];
        let accessedRoutes = [];
        if (roles.includes('admins')) {
          backAsyncRoutes = [...backRoute.asyncRoutes];
          accessedRoutes = [...backAsyncRoutes, ...asyncRoutes];
        } else {
          // 单独构建后台异步路由
          backAsyncRoutes = filterAsyncRoutes(backRoute.asyncRoutes, roles);

          // 构建其余所有异步路由
          const allAsyncRoutes = filterAsyncRoutes(asyncRoutes, roles);
          accessedRoutes = [...backAsyncRoutes, ...allAsyncRoutes];
        }
        commit('SET_BACK_ROUTES', backAsyncRoutes);
        resolve(accessedRoutes);
      });
    },
  },
};
