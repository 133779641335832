<template>
  <div class="nav-pc">
    <div class="nav-top">
      <el-input class="search" size="small" v-model="searchString">
        <el-button type="primary" slot="append" icon="el-icon-search" @click="handleOnSearch" />
      </el-input>
      <div class="lang">
        <a href="http://www.runme-plas.com/">简体中文</a>
        <a href="http://www.runme-plas.com/en/#/">ENGLISH</a>
      </div>
    </div>
    <ul class="nav">
      <li class="nav-item level-one" v-for="(item, index) in data" :key="index">
        <div class="title-box">
          <h3 class="title">
            <router-link :to="item.href">{{ item.name }}</router-link>
          </h3>
        </div>
        <div class="slide" v-if="item.children">
          <ul class="nav two">
            <!-- eslint-disable-next-line max-len -->
            <li
              class="nav-item level-two"
              v-for="(itemtwo, indextwo) in item.children"
              :key="indextwo"
            >
              <div class="title-box">
                <router-link :to="itemtwo.href ? itemtwo.href : '#'" v-if="itemtwo.iconImg">
                  <!-- eslint-disable-next-line max-len -->
                  <img
                    class="title-img"
                    :src="itemtwo.iconImg ? itemtwo.iconImg.url : ''"
                    :alt="itemtwo.name"
                  />
                </router-link>
                <h3 class="title">
                  <!-- eslint-disable-next-line max-len -->
                  <router-link :to="itemtwo.href ? itemtwo.href : '#'">{{
                    itemtwo.name
                  }}</router-link>
                </h3>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'NavTop',
    props: {
      data: {
        type: Array,
        default: () => [],
      },
    },
    components: {},
    data() {
      return {
        searchString: '',
      };
    },
    methods: {
      handleOnSearch() {
        const { searchString } = this;
        this.$router.push({
          name: 'ArticleIndex',
          query: {
            title: searchString,
          },
        });
      },
    },
  };
</script>

<style lang="scss">
  .nav-pc {
    $nav-count: 6;
    width: 100%;
    height: 100%;
    font-size: 0;
    $font-color: #000;
    $font-hover-color: #fff;
    $hover-background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    .nav-top {
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .el-button {
        background-color: var(--theme-color);
        > i {
          color: #fff;
        }
      }
      .lang {
        margin-left: 10px;
        font-size: 14px;
        cursor: pointer;
        a {
            display: block;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }
    }

    > ul.nav {
      text-align: center;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      height: auto;
      padding: 0;

      .level-one {
        position: relative;

        &:hover {
          .title {
            > a {
              color: var(--theme-color);
            }

            &::after {
              opacity: 1 !important;
            }
          }
        }

        .title {
          > a {
            font-size: 20px;
            font-weight: 500;
            color: #24303a;
            transition: all ease 0.3s;
          }

          &::after {
            display: block;
            content: '';
            width: 80%;
            height: 2px;
            background-color: var(--theme-color);
            position: absolute;
            bottom: -150%;
            left: 50%;
            transform: translateX(-50%);
            opacity: 0;
            transition: all ease 0.3s;
          }
        }
      }
    }
  }
</style>
