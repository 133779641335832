<template>
  <el-form-item
  :prop="prop"
  :label="label"
  :label-width="labelWidth"
  :required="required">
    <slot></slot>
  </el-form-item>
</template>

<script>
export default {
  name: 'ExtElFormItem',
  props: {
    prop: {
      type: String,
      require: true,
    },
    label: String,
    labelWidth: {
      type: String,
      default: 'auto',
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
