const testRoute = {
  constantRoutes: [
    {
      path: '/test',
      name: 'Test',
      component: () => import(/* webpackChunkName: "test" */ '@/views/test/home/index.vue'),
    },
    {
      path: '/test/element',
      name: 'TestElement',
      component: () => import(/* webpackChunkName: "test-element" */ '@/views/test/element/index.vue'),
    },
  ],
  asyncRoutes: [],
};

export default testRoute;
