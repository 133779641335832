import Vue from 'vue';
import App from '@/App.vue';
import store from '@/store';
import router from '@/router';

import '@/router/hooks';
import '@/directive';
import '@/filter';
import '@/plugins/element';
import '@/plugins/nprogress';
import '@/plugins/cquery';
import '@/styles/main.scss';

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
