<template>
  <el-button @click="$emit('click', $event)">
    <slot></slot>
  </el-button>
</template>

<script>
export default {
  name: 'ExtElButton',
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang='scss' scoped></style>
