import Vue from 'vue';
import LC from '@mokelao/leancloud-library';
import transformer from './transformer';

const LCKey = {
  appId: process.env.VUE_APP_LC_APP_ID,
  appKey: process.env.VUE_APP_LC_APP_KEY,
};
if (process.env.VUE_APP_LC_SERVER_URL) {
  LCKey.serverURL = process.env.VUE_APP_LC_SERVER_URL;
}

const defaultACL = {
  writeRole: ['master', 'admin'],
};
// init leancloud request
const request = new LC(LCKey, defaultACL);

request.interceptors.request.use(
  () => {},
  (err) => Promise.reject(err),
);

request.interceptors.response.use(
  (res) => {
    const data = transformer(res);
    return data;
  },
  (err) => {
    Vue.prototype.$message({
      message: err.message || '网络错误',
      type: 'error',
      duration: 5 * 1000,
    });
    return Promise.reject(err);
  },
);

export default request;
