/* Layout */
import Layout from '@/views/front/layout/index.vue';

const frontRoute = {
  constantRoutes: [
    {
      path: '/',
      component: Layout,
      children: [
        {
          path: '',
          name: 'Home',
          // route level code-splitting
          // this generates a separate chunk (front-layout.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "home" */ '@/views/front/home/index.vue'),
        },
        {
          path: 'about',
          name: 'About',
          component: () => import(/* webpackChunkName: "about" */ '@/views/front/about/index.vue'),
        },
        {
          path: 'case',
          name: 'Case',
          component: () => import(/* webpackChunkName: "case" */ '@/views/front/case/index.vue'),
        },
        {
          path: 'contact',
          name: 'Contact',
          component: () => import(/* webpackChunkName: "contact" */ '@/views/front/contact/index.vue'),
        },
        {
          path: 'message',
          name: 'Message',
          component: () => import(/* webpackChunkName: "message" */ '@/views/front/message/index.vue'),
        },
      ],
    },
    {
      path: '/service',
      name: 'Service',
      component: Layout,
      children: [
        {
          path: '',
          name: 'ServiceIndex',
          props: (route) => ({ id: route.query.cate }),
          component: () => import(/* webpackChunkName: "product" */ '@/views/front/service/index.vue'),
        },
        {
          path: ':id',
          name: 'ServiceDetail',
          props: true,
          component: () => import(/* webpackChunkName: "product-detail" */ '@/views/front/service/detail.vue'),
        },
      ],
    },
    {
      path: '/product',
      name: 'Product',
      component: Layout,
      children: [
        {
          path: '',
          name: 'ArticleIndex',
          props: (route) => ({ id: route.query.cate }),
          component: () => import(/* webpackChunkName: "product" */ '@/views/front/product/index.vue'),
        },
        {
          path: ':id',
          name: 'ArticleDetail',
          props: true,
          component: () => import(/* webpackChunkName: "product-detail" */ '@/views/front/product/detail.vue'),
        },
      ],
    },
    {
      path: '/news',
      name: 'news',
      component: Layout,
      children: [
        {
          path: '',
          name: 'NewsIndex',
          props: (route) => ({ id: route.query.cate }),
          component: () => import(/* webpackChunkName: "news" */ '@/views/front/news/list.vue'),
        },
        {
          path: ':id',
          name: 'NewsDetail',
          props: true,
          component: () => import(/* webpackChunkName: "news-detail" */ '@/views/front/news/detail.vue'),
        },
      ],
    },
  ],
  asyncRoutes: [],
};

export default frontRoute;
