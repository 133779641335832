<template>
  <el-checkbox-group v-model="myModel" :disabled="disabled" @change="$emit('change', myModel)">
   <slot></slot>
  </el-checkbox-group>
</template>

<script>
export default {
  name: 'ExtElCheckboxGroup',
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      required: true,
      type: [Array, Boolean],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myModel: this.model,
    };
  },
  watch: {
    model(val) {
      this.myModel = val;
    },
  },
};
</script>

<style></style>
