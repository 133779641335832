import router from '@/router';

// eslint-disable-next-line no-unused-vars
const initCQuery = () => {
  const {
    Accordion,
    Button,
    Setting,
    Tabs,
  } = router.app.$cq;
  Accordion.setupDefault();
  Button.setupDefault();
  Setting.setupDefault();
  Tabs.setupDefault();
};

router.afterEach(() => {
  // finish progress bar
  router.app.$progress.done();
  router.app.$nextTick(() => {
    initCQuery();
  });
});
