import request from '@/utils/request';

const Message = {
  createMessageData({ type = 'Message', data } = {}) {
    return request.Object(type).setData(data);
  },
  getMessageData({ type = 'Message', parmas } = {}) {
    return request.Query(type).getData(parmas);
  },
};

export default Message;
