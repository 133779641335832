<template>
  <el-button class="navBtn"
    :class="[size, type, { background, click: open }]"
    id="navBtn"
    @click="handleCallback">
    <span class="line"></span>
  </el-button>
</template>

<script>
export default {
  name: 'navBtn',
  props: {
    // 打开状态,默认为不打开
    open: {
      type: Boolean,
      default: false,
    },
    background: Boolean, // 按钮背景,默认无背景
    type: String, // 按钮类型
    size: String,
  },
  data() {
    return {
      // navClick: this.open
    };
  },
  methods: {
    handleCallback() {
      this.$emit('update:open', !this.open);
      this.$emit('callback', this.open);
    },
  },
};
</script>

<style lang='scss' scoped>
.navBtn {
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: background-color 0.4s ease, opacity 0.3s ease;

  > span .line {
    position: absolute;
    display: block;
    width: 60%;
    height: 2px;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transition: all 0.4s ease-out 0s;
    transform: translate(-50%, -50%);

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: inherit;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: transform 0.4s ease-out;
      transform: translateY(-300%) rotate(0deg);
    }

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: inherit;
      position: absolute;
      top: 0px;
      left: 0px;
      transition: transform 0.4s ease-out;
      transform: translateY(300%) rotate(0deg);
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &.background {
    background-color: #2c2c2c;
    background-color: var(--them-color);
    > span.line {
      background-color: #fff;

      &::before {
        background-color: #fff;
      }

      &::after {
        background-color: #fff;
      }
    }
  }

  &.overlapping {
    &.click {
      > span.line {
        background-color: transparent !important;

        &::before {
          transition: transform 0.4s ease-out 0s;
          transform: rotate(45deg);
          top: 0px;
        }

        &::after {
          transition: transform 0.4s ease-out 0s;
          transform: rotate(-45deg);
          top: 0px;
        }
      }
    }
  }

  &.plain {
    > span.line {
      z-index: 2;
      width: 60%;
      left: 20%;
      transform: translateY(-50%);
      transition: transform 0.3s 0.15s ease-out, width 0s 0.1s ease,
        background-color 0.4s 0s ease;
      &::before {
        width: 100%;
        transform: translateY(360%) !important;
        transition: transform 0.5s 0s ease-out, width 0s 0.1s,
          background-color 0.3s 0s ease;
      }

      &::after {
        width: 100%;
        transform: translateY(-360%) !important;
        transition: transform 0.5s 0s ease-out, width 0s 0.1s,
          background-color 0.3s 0s ease;
      }
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      background-color: var(--them-color);
      opacity: 0;
      transition: opacity 0.4s 0s ease;
    }
    &.click {
      > span.line {
        width: 40%;
        left: 20%;
        transform: translateY(-50%) rotate(360deg);
        &::before {
          width: calc(100% * (1 + 1 / 3));
          transform: translateY(-360%) !important;
        }

        &::after {
          width: calc(100% * (1 + 1 / 3));
          transform: translateY(360%) !important;
        }
      }
    }
    &:hover {
      > span.line {
        background-color: #fff;

        &::before {
          background-color: #fff;
        }

        &::after {
          background-color: #fff;
        }
      }
      &::after {
        opacity: 1;
      }
    }
  }

  &.medium {
    width: 40px;
    height: 40px;
    background-color: var(--theme-color);
    > span.line {
      &::before {
        transform: translateY(-360%) rotate(0deg);
      }

      &::after {
        transform: translateY(360%) rotate(0deg);
      }
    }
  }
}
</style>
