<template>
  <!-- 整站网站页面容器(包裹整个网站，可用于统一网站页面背景等) -->
  <div class="page-container">
    <!-- 头部 -->
    <header>
      <div class="layout-block">
        <router-link to="/" class="header-logo">
          <img src="~@/assets/img/home/logo.png" alt="img" />
        </router-link>
        <nav-phone class="mobile-nav" :data="navigation"></nav-phone>
        <nav-top class="pc-nav" :data="navigation"></nav-top>
      </div>
    </header>
    <!-- 布局容器(放置页面内容，可用于统一页面内容最大宽度) -->
    <!-- class="layout-container" -->
    <main>
      <router-view />
    </main>
    <!-- 底部 -->
    <footer>
      <div class="layout-block">
        <div class="footer-block-1">
          <router-link to="/" class="footer-logo">
            <img src="~@/assets/img/home/logo.png" alt="logo" />
          </router-link>
        </div>
        <div class="footer-block-2">
          <ul class="footer-nav">
            <li v-for="(item, index) in navigation" :key="index">
              <router-link :to="item.href">{{ item.name }}</router-link>
            </li>
          </ul>
          <div class="footer-contact">
            <ul class="footer-contact-left">
              <li>
                <p>联系人：王先生</p>
              </li>
              <li>
                <p>手机: <a href="tel:86-13392082379">+86-133 9208 2379 (24小时)</a></p>
              </li>
              <li>
                <p>电话/传真: <a href="tel:0750-3772819">0750-377 2819</a></p>
              </li>
              <li>
                <p>邮箱: wzxgd@126.com</p>
              </li>
              <li>
                <p>地址: 江门市蓬江区棠下镇三堡丰泰工业园二区B座</p>
              </li>
            </ul>
            <div class="footer-contact-right">
              <div class="erweima-box">
                <img src="~@/assets/img/layout/website-code.jpg" alt="img" />
                <p>网站二维码</p>
              </div>
              <div class="erweima-box">
                <img src="~@/assets/img/layout/wechat-code.jpg" alt="img" />
                <p>微信二维码</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="fotter-bottom">
        opyright & copy 江门市蓬江区元茂塑料制品厂 版权所有 江门网站优化：<a
          href="http://manage.zorvin.com/"
          target="_blank"
          >卓瀛科技</a
        >
      </p>
    </footer>
  </div>
</template>

<script>
  import NavServices from '@/services/navigation';
  import messageService from '@/services/message';
  import NavTop from './components/NavTop.vue';
  import NavPhone from './components/NavPhone.vue';

  export default {
    name: 'FrontLayout',
    components: { NavTop, NavPhone },
    data() {
      const checkPhone = (rule, value, callback) => {
        const pass = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value);
        if (pass) {
          callback();
        } else {
          callback(new Error('请输入正确的手机号码'));
        }
      };
      return {
        navigation: null,
        formLoading: false,
        form: {
          name: '',
          phone: '',
          content: '',
        },
        rules: {
          name: [
            {
              required: true,
              message: '请输入活动名称',
              trigger: 'blur',
            },
            {
              min: 1,
              max: 10,
              message: '长度在 1 到 10 个字符',
              trigger: 'blur',
            },
          ],
          phone: [
            {
              required: true,
              message: '请输入电话号码',
              trigger: 'blur',
            },
            {
              validator: checkPhone,
              trigger: 'blur',
            },
          ],
          content: [
            {
              required: true,
              message: '请输入留言内容',
              trigger: 'blur',
            },
            {
              min: 1,
              max: 200,
              message: '长度在 1 到 200 个字符',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    created() {
      this.getNavData();
    },
    methods: {
      getNavData() {
        const parmas = {};
        NavServices.getNavData(parmas).then((res) => {
          this.navigation = [...res.result];
        });
      },
      submitMessage() {
        this.formLoading = true;
        messageService
          .createMessageData({ data: [this.form] })
          .then(() => {
            this.$message({
              message: '留言提交成功',
              type: 'success',
            });
          })
          .catch(() => {
            this.$message({
              message: '留言提交失败',
              type: 'error',
            });
          })
          .finally(() => {
            this.formLoading = false;
          });
      },
      submit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.submitMessage();
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .layout-block {
    padding: 0 20px;
  }
  header {
    width: 100%;
    .layout-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 125px;
      @include media-breakpoint-down(md) {
        height: 90px;
      }
      .header-logo {
        display: block;
        flex: 1;
        margin-right: 10px;
        img {
          width: 100%;
        }
        h2 {
          @include media-breakpoint-down(md) {
            font-size: 20px;
          }
        }
      }
      .pc-nav {
        width: auto;
        flex: 1;
        max-width: 670px;
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
      .mobile-nav {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }
  }
  footer {
    padding: 70px 0 30px 0;
    // background-image: url('@/assets/img/home/footer-bg.jpg');
    background-color: var(--theme-color);
    // background-position: center;
    // background-size: cover;
    color: #fff;
    @include media-breakpoint-down(md) {
      padding: 40px 0;
    }

    a {
      color: #fff;

      img {
        width: 100%;
        padding-right: 10px;
      }
    }

    .layout-block {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-breakpoint-down(md) {
        display: block;
      }
      .footer-block-1 {
        flex: 1;
        display: block;
        text-align: center;
        min-width: 400px;
        @include media-breakpoint-down(md) {
          min-width: 0;
        }
        h2 {
          font-size: 30px;
          margin: 10px 5px;
          @include media-breakpoint-down(md) {
            font-size: 24px;
          }
        }
        span {
          display: block;
          font-size: 14px;
          font-weight: bold;
          text-transform: uppercase;
          @include media-breakpoint-down(md) {
            font-size: 12px;
          }
        }
      }
      .footer-block-2 {
        flex: 2;
        .footer-nav {
          max-width: 540px;
          display: flex;
          justify-content: space-between;
          border-bottom: 2px solid #fff;
          @include media-breakpoint-down(md) {
            max-width: 100%;
            flex-wrap: wrap;
          }
          li {
            flex: 1;
            text-align: center;
            color: #fff;
            padding: 10px 5px;
            @include media-breakpoint-down(md) {
              width: 33%;
              flex: auto;
            }
          }
        }
        .footer-contact {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include media-breakpoint-down(md) {
            display: block;
          }
          .footer-contact-left {
            li {
              margin-top: 10px;
              width: 100%;
              display: flex;
              align-items: center;
              color: #fff;
              img {
                width: 30px;
                margin-right: 5px;
              }
              p {
                font-size: 16px;
              }
            }
          }
          .footer-contact-right {
            display: flex;
            min-width: 280px;
            justify-content: space-between;
            @include media-breakpoint-down(md) {
              margin-top: 15px;
              min-width: 0;
              width: 100%;
            }
            .erweima-box {
              flex: 1;
              margin-left: 15px;
              max-width: 100px;
              img {
                width: 100%;
                height: auto;
              }
              p {
                font-size: 12px;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .fotter-bottom {
      margin-top: 30px;
      text-align: center;
      padding: 5px 20px;
      border-top: 2px solid #fff;
    }
  }
</style>
