<template>
  <el-checkbox
  v-model="myModel"
  :label="label"
  :indeterminate="indeterminate"
  :disabled="disabled"
  @change="$emit('change', myModel)"
    ><slot></slot>
  </el-checkbox>
</template>

<script>
export default {
  name: 'ExtElCheckbox',
  model: {
    prop: 'model',
    event: 'change',
  },
  props: {
    model: {
      type: [Array, Boolean],
      default: () => [],
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
    label: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myModel: this.model,
    };
  },
  watch: {
    model(val) {
      this.myModel = val;
    },
  },
};
</script>

<style></style>
