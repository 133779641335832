import systemRoute from './modules/system';
import frontRoute from './modules/front';
import backRoute from './modules/back';
import testRoute from './modules/test';
import errorRoute from './modules/error';

/**
 * 常量路由
 * 没有权限要求的基本页面
 * 所有角色都可以访问
 */
export const constantRoutes = [
  ...systemRoute.constantRoutes,
  ...frontRoute.constantRoutes,
  ...backRoute.constantRoutes,
  ...testRoute.constantRoutes,
  ...errorRoute.constantRoutes,
];

/**
 * 异步路由
 * 需要根据用户角色动态加载的路由(后台异步路由需单独构建)
 */
export const asyncRoutes = [
  ...systemRoute.asyncRoutes,
  ...frontRoute.asyncRoutes,
  ...testRoute.asyncRoutes,
  // 404 error page must be placed at the end !!!
  ...errorRoute.asyncRoutes,
];

export default {
  systemRoute,
  frontRoute,
  backRoute,
  errorRoute,
  testRoute,
};
