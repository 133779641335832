export default function transformer(result) {
  let response = result;
  if (result instanceof Array) {
    const data = [];
    result.forEach((item) => data.push(item.toJSON()));
    response = data;
  } else if (result instanceof Object && result.toJSON) {
    response = result.toJSON();
  }
  return response;
}
